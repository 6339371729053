import { authorizedRDRequestsv2 } from "../httpRequest";

export interface StupidBaseResponse {
  '$id': string;
  '$type': string;
}
export interface InitPaymentResponse extends StupidBaseResponse{
  WalletToken:string;
  WalletUri: string;
  Mode: number;
}
const walletApiRequest = {
  initiatePayment: async ():Promise<InitPaymentResponse> => {
    const response = await authorizedRDRequestsv2.post<InitPaymentResponse>(
      `webaccesswallet/init-wallet-payment`,
      {}
    );
    return response;
  },
};

export default walletApiRequest;
