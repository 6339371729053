import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import walletApiRequest, { InitPaymentResponse } from "../../../../app/api/customer/walletApiRequest";

interface WalletState {
  walletUri: string;
  walletToken: string;
  mode: number;
  loading: boolean;
  error: string | null;
}

export const initWalletPayment = createAsyncThunk<
  InitPaymentResponse
>(
  'wallet/initWalletPayment',
  async (data, thunkAPI) => {
    try {
      const response = await walletApiRequest.initiatePayment();
      return response;
    } catch (error: any) {
      if (error.data) {
        return thunkAPI.rejectWithValue(error.data.message);
      }
      return thunkAPI.rejectWithValue('An unknown error occurred');
    }
  }
);

const walletDataSlice = createSlice({
  name: 'walletData',
  initialState: {
    walletToken: '',
    walletUri: '',
    loading: false,
    error: null,
  } as WalletState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initWalletPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.walletToken = '';
        state.walletUri = '';
      })
      .addCase(initWalletPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.walletUri = action.payload.WalletUri;
        state.mode = action.payload.Mode;
      })
      .addCase(initWalletPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default walletDataSlice.reducer;