import { rdRequests, authorizedRDRequests } from "../httpRequest";

const merchandiseApiRequest = {
    getOnlineStoreDepartments: () => rdRequests.get(`WebAccessMerchandise/Merchandise/MerchandiseOnlineStoreDepartments`),
    getOnlineStoreCategories: (departmentId: number) => rdRequests.get(`WebAccessMerchandise/Merchandise/MerchandiseOnlineStoreCategories?departmentId=${departmentId}`),
    getMerchandiseItems: (searchMerchandise: any) => rdRequests.post(`WebAccessMerchandise/Merchandise/GetMerchandiseItems`, searchMerchandise),
    getMerchandiseItemById: (itemLookupCode: string) => rdRequests.get(`WebAccessMerchandise/Merchandise/GetMerchandiseItemById?itemLookupCode=${itemLookupCode}`),
    purchaseMerchandise: (purchaseMerchandiseRequest: any) => authorizedRDRequests.post(`WebAccessMerchandise/Merchandise/PurchaseMerchandise`, purchaseMerchandiseRequest)
}

export default merchandiseApiRequest;