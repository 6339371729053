import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { showContentLoader, hideContentLoader } from "../../../loader/loaderSlice";
import unitGridApiRequest from "../../../../app/api/facilities/unitGridApiRequest";
import { store } from "../../../../app/store/configureStore";
import { formatDate, getFormattedTime, onGridLoad } from "../../../../app/util/util";
import { UnitGridData } from "../../../../app/models/unitGridData";
import { EnterpriseSetting } from "../../../../app/models/common/enterpriseSetting";
import navigationService from "../../../../app/history/history";
import { setAlertMessage } from "../../../../modules/common/settingsSlice";

interface UnitGridState {
    facilityDetail: any,
    unitGridData: UnitGridData | any,
    unitDetail: any,
    isTimeBaseGrid: boolean,
    isOpenCampingGrid: boolean,
    timeBaseHoursArray: any[],
    selectedHour: string,
    selectedTimeBaseGridData: any[],
    furthestCampsitesDate: string,
    unitFavouriteList: any,
    isProcessPreCart: boolean
}

const initialState: UnitGridState = {
    facilityDetail: null,
    unitGridData: null,
    unitDetail: null,
    isTimeBaseGrid: false,
    isOpenCampingGrid: false,
    timeBaseHoursArray: [],
    selectedHour: '0:0',
    selectedTimeBaseGridData: [],
    furthestCampsitesDate: "",
    unitFavouriteList: [],
    isProcessPreCart: false
}

const getDateRestriction = async (facilityId: any, callback: any) => {
    let enterpriseSettings = store.getState().common.enterpriseSetting;
    let furthestCampsitesIds: any = enterpriseSettings.find((e: EnterpriseSetting) => { return e.Name == "FurthestCampsitesIds" });
    if (furthestCampsitesIds) {
        furthestCampsitesIds = furthestCampsitesIds.Value;
    } else {
        furthestCampsitesIds = "";
    }
    let furthestDefaultCampsitesId: any = enterpriseSettings.find((e: EnterpriseSetting) => { return e.Name == "FurthestDefaultCampsitesId" });
    if (furthestDefaultCampsitesId) {
        furthestDefaultCampsitesId = furthestDefaultCampsitesId.Value;
    } else {
        furthestDefaultCampsitesId = "";
    }
    if (furthestCampsitesIds !== "" && furthestDefaultCampsitesId !== "") {
        const dateRestriction = await unitGridApiRequest.getDateRestriction(facilityId, furthestDefaultCampsitesId);
        callback(dateRestriction.FutureBookingEnds);
    } else {
        callback("");
    }
}

const getHourArray = (timebaseMinHours: any, timebaseMaxHours: any, timebaseDuration: any, defaultLabel: any) => {
    let timeArray = [{ label: defaultLabel, value: "0:0" }];
    let startTime = new Date(2010, 0, 1, timebaseMinHours, 0, 0, 0).getTime();
    let endTime = new Date(2010, 0, 1, timebaseMaxHours, 0, 0, 0).getTime();
    timebaseDuration = (timebaseDuration) ? timebaseDuration : 60;
    while (startTime <= endTime) {
        let hour = new Date(startTime).getHours();
        let minute = new Date(startTime).getMinutes();
        if (minute > 0) {
            timeArray.push({ label: hour + " Hours " + minute + " Minutes", value: hour + ":" + minute });
        } else {
            timeArray.push({ label: hour + " Hours", value: hour + ":" + minute });
        }
        startTime = startTime + (1000 * 60 * timebaseDuration);
    }
    let defaultSelectedHour = timeArray.length === 2 ? timeArray[1].value : timeArray[0].value;
    return { timeArray, defaultSelectedHour };
}

const processGridSlice = (gridData: any) => {
    let enterpriseSettings = store.getState().common.enterpriseSetting;
    let furthestCampsitesIds: any = enterpriseSettings.find((e: EnterpriseSetting) => { return e.Name == "FurthestCampsitesIds" });
    if (furthestCampsitesIds) {
        furthestCampsitesIds = furthestCampsitesIds.Value;
    } else {
        furthestCampsitesIds = "";
    }
    let furthestDefaultCampsitesId: any = enterpriseSettings.find((e: any) => { return e.Name == "FurthestDefaultCampsitesId" });
    if (furthestDefaultCampsitesId) {
        furthestDefaultCampsitesId = furthestDefaultCampsitesId.Value;
    } else {
        furthestDefaultCampsitesId = "";
    }
    let isReservationDrawingShow: any = enterpriseSettings.find((e: any) => { return e.Name == "EnableReservationDrawing" });
    if (isReservationDrawingShow) {
        isReservationDrawingShow = Boolean(isReservationDrawingShow.Value);
    } else {
        isReservationDrawingShow = false;
    }
    gridData = Object.assign({}, gridData);
    let endDate = null;
    if (typeof (gridData.EndDate) === "object") {
        endDate = new Date(gridData.EndDate);
    } else {
        endDate = new Date(gridData.EndDate.replace(/-/g, '/'));
    }
    let currentDate = new Date(gridData.StartDate.replace(/-/g, '/'));
    let futureEndDate = new Date(gridData.MaxDate.replace(/-/g, '/'));
    for (let key in gridData.Facility.Units) {
        let newSlice: any = {};
        currentDate = new Date(gridData.StartDate.replace(/-/g, '/'));
        while (currentDate <= endDate) {
            let sliceObj: any = {};
            let avaSliceKey = "";
            let createDate = (currentDate.getFullYear()) + "-" + ((currentDate.getMonth() + 1).toString().length === 1 ? "0" + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)) + "-" + (currentDate.getDate().toString().length === 1 ? ("0" + currentDate.getDate().toString()) : currentDate.getDate()) + "T00:00:00";
            for (let val in gridData.Facility.Units[key].Slices) {
                if (createDate !== val) {
                    avaSliceKey = "";
                }
                else {
                    avaSliceKey = val;
                    break;
                }
            }
            let fd = new Date(futureEndDate);
            let cd = (currentDate.getFullYear()) + "-" + ((currentDate.getMonth() + 1).toString().length === 1 ? "0" + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)) + "-" + (currentDate.getDate().toString().length === 1 ? ("0" + currentDate.getDate().toString()) : currentDate.getDate());
            let ccd = new Date(cd.replace(/-/g, '/') + " 12:00:00 AM");
            let fCampsitesDate = store.getState().unitGrid.furthestCampsitesDate ? new Date(store.getState().unitGrid.furthestCampsitesDate) : null;
            if (avaSliceKey !== "") {
                let sliceval = gridData.Facility.Units[key].Slices[avaSliceKey];
                sliceObj = {
                    Date: sliceval.Date,
                    MinStay: sliceval.MinStay,
                    IsFree: sliceval.IsFree,
                    IsBlocked: sliceval.IsBlocked,
                    IsWalkin: sliceval.IsWalkin,
                    ReservationId: sliceval.ReservationId,
                    Lock: sliceval.Lock,
                    IsSeason: false,
                    Departure: false,
                    IsReservationDraw: isReservationDrawingShow ? (sliceval.IsReservationDraw !== undefined ? sliceval.IsReservationDraw : false) : false,
                    IsRestriction: false
                };
                if (fCampsitesDate && ccd > fCampsitesDate && furthestCampsitesIds !== "" && furthestDefaultCampsitesId !== "" && furthestCampsitesIds.includes(gridData.Facility.Units[key].UnitCategoryId) && sliceObj.ReservationId === 0) {
                    sliceObj.Departure = true;
                }
            } else {
                let IsSeasonAvailable = false;
                let seasonkey = currentDate.getFullYear() + "-" + ((currentDate.getMonth() + 1).toString().length === 1 ? "0" + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)) + "-" + (currentDate.getDate().toString().length === 1 ? "0" + currentDate.getDate() : currentDate.getDate()) + "T00:00:00";
                let seasonDates = gridData.Facility.SeasonDates[seasonkey];
                if (seasonDates !== undefined) {
                    IsSeasonAvailable = seasonDates;
                }
                else {
                    IsSeasonAvailable = true;
                }
                if (IsSeasonAvailable) {
                    if (ccd > fd) {
                        sliceObj = {
                            Date: cd,
                            MinStay: 1,
                            IsFree: false,
                            IsBlocked: false,
                            IsWalkin: false,
                            ReservationId: 0,
                            Lock: null,
                            IsSeason: false,
                            Departure: true,
                            IsReservationDraw: false,
                            IsRestriction: false
                        };
                    }
                    else if (fCampsitesDate && ccd > fCampsitesDate && furthestCampsitesIds !== "" && furthestDefaultCampsitesId !== "" && furthestCampsitesIds.includes(gridData.Facility.Units[key].UnitCategoryId) && (sliceObj.ReservationId === 0 || sliceObj.ReservationId === undefined)) {
                        sliceObj = {
                            Date: cd,
                            MinStay: 1,
                            IsFree: false,
                            IsBlocked: false,
                            IsWalkin: false,
                            ReservationId: 0,
                            Lock: null,
                            IsSeason: true,
                            Departure: true,
                            IsReservationDraw: false,
                            IsRestriction: false
                        };
                    }
                    else {
                        sliceObj = {
                            Date: cd,
                            MinStay: 1,
                            IsFree: false,
                            IsBlocked: false,
                            IsWalkin: false,
                            ReservationId: 0,
                            Lock: null,
                            IsSeason: true,
                            Departure: false,
                            IsReservationDraw: false,
                            IsRestriction: true
                        };
                    }
                }
                else {
                    sliceObj = {
                        Date: cd,
                        MinStay: 1,
                        IsFree: false,
                        IsBlocked: false,
                        IsWalkin: true,
                        ReservationId: 0,
                        Lock: null,
                        IsSeason: false,
                        Departure: false,
                        IsReservationDraw: false,
                        IsRestriction: true
                    };
                }
            }
            newSlice[createDate] = sliceObj;
            currentDate.setDate(currentDate.getDate() + 1);
        }
        let sliceArr: any = Object.values(newSlice);
        if (sliceArr.length > 0 && sliceArr[0].IsReservationDraw === true) {
            gridData.Facility.Units[key].IsReservationDraw = true;
        } else {
            gridData.Facility.Units[key].IsReservationDraw = false;
        }
        gridData.Facility.Units[key].Slices = newSlice;
    }
    return Object.assign({}, gridData);
}

const getUnitImage = (unitDetail: any) => {
    let unitImageType = ".png";
    let unitImageName = unitDetail.MapInfo ? unitDetail.MapInfo.UnitImage : "";
    let path = "images/";
    let imagepath = path + unitImageName + ".NotAvailable.png";
    let IsAvailable = false;
    let slice = null;
    let slices = unitDetail.Slices;

    for (let key in slices) {
        slice = slices[key];
        break;
    }

    if (slice !== null) {
        if (slice.IsFree && slice.Lock === null) {
            IsAvailable = true;
        }

        if (IsAvailable) {
            if (slice.ReservationId !== 0 || slice.IsBlocked || slice.IsWalkin) {
                IsAvailable = false;
            }
        }

        if (unitDetail.IsWebViewable) {
            if (unitDetail.AllowWebBooking) {
                if (slice.IsFree && slice.Lock === null && slice.IsWalkin === false && slice.IsBlocked === false && slice.ReservationId === 0) {
                    //slice.Lock !==  null &&
                    unitImageType = ".png";
                } else {
                    unitImageType = ".NotAvailable.png";
                }
            } else {
                if (slice.IsBlocked) {
                    unitImageType = ".NotAvailable.png";
                } else {
                    unitImageType = ".NoWebBooking.png";
                }
            }
        }

        if (slice.IsWalkin === true) {
            unitImageName = "/" + store.getState().common.enterpriseDetail.enterpriseName + "/Units/WalkIn";
            unitImageType = ".NoWebBooking.png";
        }
    }

    imagepath = path + unitImageName + unitImageType;
    return imagepath;
};

const processUnitImage = (gridResults: any, selectedNight: any) => {
    let webSiteSettings = store.getState().common.webSiteSettings;
    let startDate = new Date(gridResults.StartDate.replace(/-/g, '/'));
    let endDate = new Date(gridResults.StartDate.replace(/-/g, '/'));
    endDate.setDate(endDate.getDate() + (selectedNight - 1));
    let facilityData = gridResults.Facility;
    for (let key in facilityData.Units) {
        facilityData.Units[key].UnitImage = getUnitImage(facilityData.Units[key]);
    }
    for (let key in facilityData.Units) {
        let allNightSliceFree = true;
        for (let s in facilityData.Units[key].Slices) {
            if (startDate <= new Date(facilityData.Units[key].Slices[s].Date + "T00:00:00") && endDate >= new Date(facilityData.Units[key].Slices[s].Date + "T00:00:00")) {
                if (facilityData.Units[key].Slices[s].IsFree && facilityData.Units[key].Slices[s].Lock === null && facilityData.Units[key].Slices[s].IsBlocked === false && facilityData.Units[key].Slices[s].ReservationId === 0) {
                }
                else {
                    if (facilityData.Units[key].Slices[s].IsFutureEndDate === "true" && facilityData.Units[key].Slices[s].ReservationId === 0) {
                    }
                    else {
                        allNightSliceFree = false;
                        break;
                    }
                }
            }
        }
        if (!allNightSliceFree && facilityData.Units[key].MapInfo) {
            let enterpriseSettings = store.getState().common.enterpriseSetting;
            let originalSite: any = enterpriseSettings.find((e: EnterpriseSetting) => { return e.Name == "SaturnUrl" });
            let vbtImageBaseUrl = "";
            if (facilityData.Units[key].MapInfo.UnitImageVBT) {
                vbtImageBaseUrl = facilityData.Units[key].MapInfo.UnitImageVBT.split("images");
                if (vbtImageBaseUrl && vbtImageBaseUrl.length > 0) {
                    vbtImageBaseUrl = vbtImageBaseUrl[0];
                }
            }
            if (originalSite) {
                originalSite = originalSite.Value
            }
            if (facilityData.Units[key].MapInfo) {
                if (vbtImageBaseUrl) {
                    facilityData.Units[key].UnitImage = vbtImageBaseUrl + "images/" + facilityData.Units[key].MapInfo.UnitImage + ".NotAvailable.png";
                    facilityData.Units[key].MapInfo.UnitImage = vbtImageBaseUrl + "images/" + facilityData.Units[key].MapInfo.UnitImage + ".NotAvailable.png";
                    facilityData.Units[key].MapInfo.UnitImageVBT = vbtImageBaseUrl + "images/" + facilityData.Units[key].MapInfo.UnitImage + ".NotAvailable.png";
                }
                else {
                    facilityData.Units[key].UnitImage = originalSite + "images/" + facilityData.Units[key].MapInfo.UnitImage + ".NotAvailable.png";
                    facilityData.Units[key].MapInfo.UnitImage = originalSite + "images/" + facilityData.Units[key].MapInfo.UnitImage + ".NotAvailable.png";
                    facilityData.Units[key].MapInfo.UnitImageVBT = originalSite + "images/" + facilityData.Units[key].MapInfo.UnitImage + ".NotAvailable.png";
                }

                if (webSiteSettings && webSiteSettings.enable_esri_map) {
                    facilityData.Units[key].UnitImage = facilityData.Units[key].MapInfo.UnitImage;
                    facilityData.Units[key].MapInfo.UnitImage = facilityData.Units[key].MapInfo.UnitImage;
                    facilityData.Units[key].MapInfo.UnitImageVBT = facilityData.Units[key].MapInfo.UnitImage;
                }

            }
        }
    }
    gridResults.Facility = facilityData;
    return gridResults;
}

const getAllTimeArray = (date: any, timebaseMinHours: any, timebaseMaxHours: any, timebaseDuration: any) => {
    let timeArray = [];
    let startTime = new Date(date + "T" + ("0" + timebaseMinHours).slice(-2) + ":00:00").getTime();
    let endTime = new Date(date + "T" + ("0" + timebaseMaxHours).slice(-2) + ":00:00").getTime();
    timebaseDuration = (timebaseDuration) ? timebaseDuration : 15;
    while (startTime < endTime) {
        let hour = new Date(startTime).getHours();
        let minute = new Date(startTime).getMinutes();
        timeArray.push(formatDate(startTime, store.getState().common.applicationSettings.apiDateFormat) + "T" + ("0" + hour).slice(-2) + ":" + ("0" + minute).slice(-2) + ":00");
        startTime = startTime + (1000 * 60 * timebaseDuration);
    }
    return timeArray;
}


const processTimeBaseGridResult = (gridData: any, timeBaseDuration: any) => {
    let endDate: any = null;
    if (typeof (gridData.EndDate) == "object") {
        endDate = new Date(gridData.EndDate);
    } else {
        endDate = new Date(gridData.EndDate.replace(/-/g, '/'));
    }
    let currentDate: any = new Date(gridData.StartDate.replace(/-/g, '/'));
    let futureEndDate: any = new Date(gridData.MaxDate.replace(/-/g, '/'));
    for (let key in gridData.Facility.Units) {
        let newSlice: any = {};
        currentDate = new Date(gridData.StartDate.replace(/-/g, '/'));
        let slices: any = Object.values(gridData.Facility.Units[key].Slices);
        slices = slices.sort((a: any, b: any) => { return new Date(a.Date).getTime() - new Date(b.Date).getTime() });
        while (currentDate <= endDate) {
            let sliceObj: any = {};
            let createDate = formatDate(currentDate, store.getState().common.applicationSettings.apiDateFormat);
            let formattedDateArray = getAllTimeArray(createDate, gridData.Facility.Units[key].StartTime, gridData.Facility.Units[key].EndTime, timeBaseDuration);
            let dateArray = slices.filter((ele: any) => { return formatDate(ele.Date, store.getState().common.applicationSettings.apiDateFormat) == createDate });
            let fd = new Date(futureEndDate);
            let cd = (currentDate.getFullYear()) + "-" + ((currentDate.getMonth() + 1).toString().length == 1 ? "0" + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)) + "-" + (currentDate.getDate().toString().length == 1 ? ("0" + currentDate.getDate().toString()) : currentDate.getDate());
            let ccd = new Date(cd.replace(/-/g, '/') + " 12:00:00 AM");
            if (dateArray.length > 0) {
                let isAvailable = false;
                let sliceDateTime: any = [];
                let sliceTime: any = [];
                let isBlocked = true;
                let isWalkIn = true;
                formattedDateArray.forEach((date, index) => {
                    let time = getFormattedTime(date);
                    sliceTime.push(time);
                    let sliceStartTime = new Date(date);
                    let sliceEndTime = new Date(sliceStartTime.getTime() + (1000 * 60 * timeBaseDuration));
                    let sliceArray = dateArray.filter((ele: any) => { return sliceStartTime.getTime() <= new Date(ele.Date).getTime() && new Date(ele.Date).getTime() < sliceEndTime.getTime(); });
                    if (sliceArray && sliceArray.length > 0) {
                        let isFreeSlice: any = true;
                        let isBlockedSlice: any = true;
                        let isWalkinSlice: any = true;
                        let reservationId: any = 1;
                        let isLockSlice: any = true;
                        sliceArray.forEach((sliceval: any) => {
                            if (!sliceval.IsFree || sliceval.IsBlocked || sliceval.IsWalkin || sliceval.Lock !== null || sliceval.ReservationId > 0) {
                                isFreeSlice = false;
                            }
                            if (!sliceval.IsBlocked) {
                                isBlockedSlice = false;
                            }
                            if (!sliceval.IsWalkin) {
                                isWalkinSlice = false;
                            }
                            if (sliceval.Lock === null) {
                                isLockSlice = null;
                            }
                            if (sliceval.ReservationId === 0) {
                                reservationId = 0;
                            }
                        });
                        if (isFreeSlice && !isWalkinSlice && isLockSlice == null && isBlockedSlice == false && reservationId == 0) {
                            isAvailable = true;
                        }
                        if (!isBlockedSlice) {
                            isBlocked = false;
                        }
                        if (!isWalkinSlice) {
                            isWalkIn = false;
                        }
                        if (timeBaseDuration / 15 !== sliceArray.length) {
                            isFreeSlice = false;
                            isBlockedSlice = false;
                            isWalkinSlice = false;
                            reservationId = 0;
                            isLockSlice = null;
                        }
                        sliceDateTime.push({
                            Date: date,
                            MinStay: 1,
                            IsFree: isFreeSlice,
                            IsBlocked: isBlockedSlice,
                            IsWalkin: isWalkinSlice,
                            ReservationId: reservationId,
                            Lock: isLockSlice,
                            IsSeason: false,
                            Departure: false,
                            Time: time
                        });
                    } else {
                        isBlocked = false;
                        isWalkIn = false;
                        sliceDateTime.push({
                            Date: date,
                            MinStay: 1,
                            IsFree: false,
                            IsBlocked: false,
                            IsWalkin: false,
                            ReservationId: 0,
                            Lock: null,
                            IsSeason: false,
                            Departure: false,
                            Time: time
                        });
                    }
                });
                sliceObj = {
                    Date: cd,
                    MinStay: 1,
                    IsFree: isAvailable,
                    IsBlocked: isBlocked,
                    IsWalkin: isWalkIn,
                    ReservationId: 0,
                    Lock: null,
                    IsSeason: false,
                    Departure: false,
                    DateTimeArr: sliceDateTime,
                    SliceTime: sliceTime
                };
            } else {
                let sliceDateTime: any = [];
                let sliceTime: any = [];

                let IsSeasonAvailable: any = false;
                let seasonkey: any = currentDate.getFullYear() + "-" + ((currentDate.getMonth() + 1).toString().length == 1 ? "0" + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)) + "-" + (currentDate.getDate().toString().length == 1 ? "0" + currentDate.getDate() : currentDate.getDate()) + "T00:00:00";
                let seasonDates: any = gridData.Facility.SeasonDates[seasonkey];
                if (seasonDates != undefined) {
                    IsSeasonAvailable = seasonDates;
                }
                else {
                    IsSeasonAvailable = true;
                }
                if (IsSeasonAvailable) {
                    if (ccd > fd) {
                        formattedDateArray.forEach((ele) => {
                            let time = getFormattedTime(ele);
                            sliceTime.push(time);
                            sliceDateTime.push({
                                Date: ele,
                                MinStay: 1,
                                IsFree: false,
                                IsBlocked: false,
                                IsWalkin: false,
                                ReservationId: 0,
                                Lock: null,
                                IsSeason: false,
                                Departure: true,
                                Time: time
                            });
                        })
                        sliceObj = {
                            Date: cd,
                            MinStay: 1,
                            IsFree: false,
                            IsBlocked: false,
                            IsWalkin: false,
                            ReservationId: 0,
                            Lock: null,
                            IsSeason: false,
                            Departure: true,
                            DateTimeArr: sliceDateTime,
                            SliceTime: sliceTime
                        };
                    }
                    else {
                        formattedDateArray.forEach((ele) => {
                            let time = getFormattedTime(ele);
                            sliceTime.push(time);
                            sliceDateTime.push({
                                Date: ele,
                                MinStay: 1,
                                IsFree: false,
                                IsBlocked: false,
                                IsWalkin: false,
                                ReservationId: 0,
                                Lock: null,
                                IsSeason: true,
                                Departure: false,
                                Time: time
                            });
                        })
                        sliceObj = {
                            Date: cd,
                            MinStay: 1,
                            IsFree: false,
                            IsBlocked: false,
                            IsWalkin: false,
                            ReservationId: 0,
                            Lock: null,
                            IsSeason: true,
                            Departure: false,
                            DateTimeArr: sliceDateTime,
                            SliceTime: sliceTime
                        };
                    }
                }
                else {
                    formattedDateArray.forEach((ele) => {
                        let time = getFormattedTime(ele);
                        sliceTime.push(time);
                        sliceDateTime.push({
                            Date: ele,
                            MinStay: 1,
                            IsFree: false,
                            IsBlocked: false,
                            IsWalkin: true,
                            ReservationId: 0,
                            Lock: null,
                            IsSeason: false,
                            Departure: false,
                            Time: time
                        });
                    })
                    sliceObj = {
                        Date: cd,
                        MinStay: 1,
                        IsFree: false,
                        IsBlocked: false,
                        IsWalkin: true,
                        ReservationId: 0,
                        Lock: null,
                        IsSeason: false,
                        Departure: false,
                        DateTimeArr: sliceDateTime,
                        SliceTime: sliceTime
                    };
                }
            }
            newSlice[createDate] = sliceObj;
            currentDate.setDate(currentDate.getDate() + 1);
        }
        gridData.Facility.Units[key].Slices = newSlice;
    }
    return gridData;
}

export const getFacilityDetailById = createAsyncThunk<any, { facilityId: any, searchData: any, nights: any, parkId: any }>(
    'unitGrid/getFacilityDetailById',
    async ({ facilityId, searchData, nights, parkId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(setHourArray([]));
            thunkAPI.dispatch(setSelectedHour('0:0'));
            thunkAPI.dispatch(setSelectedTimeBaseGridData([]));
            thunkAPI.dispatch(setFacilityDetail({ facilityDetail: null }));
            thunkAPI.dispatch(showContentLoader());
            const facilityDetail = await unitGridApiRequest.getFacilityDetailById(facilityId);
            thunkAPI.dispatch(hideContentLoader());
            getDateRestriction(facilityId, (furthestCampsitesDate: string) => {
                thunkAPI.dispatch(setFurthestCampsitesDate(furthestCampsitesDate));
                let isTimeBaseGrid = false;
                let isOpenCampingGrid = false;
                if (facilityDetail.FacilityTypeNew === 2) {
                    isOpenCampingGrid = true;
                } else if (facilityDetail.FacilityBehaviourType === 2) {
                    isTimeBaseGrid = true;
                    let dynamicMessage = store.getState().common.dynamicConfigMsg;
                    let hourArray = getHourArray(facilityDetail.TimebaseMinHours, facilityDetail.TimebaseMaxHours, facilityDetail.TimebaseDuration, dynamicMessage['tbm_hour_default_text']);
                    thunkAPI.dispatch(setHourArray(hourArray.timeArray));
                    thunkAPI.dispatch(setSelectedHour(hourArray.defaultSelectedHour));
                }
                thunkAPI.dispatch(setFacilityDetail({ facilityDetail, isTimeBaseGrid, isOpenCampingGrid }));
                setTimeout(() => {
                    thunkAPI.dispatch(processUnitGrid({ facilityId, searchData, nights: ((isTimeBaseGrid && store.getState().common.isMobileDevice) ? 4 : nights), parkId }));
                }, 0);
                return;
            });
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            thunkAPI.dispatch(setFacilityDetail({ facilityDetail: null }));
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const processUnitGrid = createAsyncThunk<any, { facilityId: any, searchData: any, nights: any, parkId: any }>(
    'unitGrid/processUnitGrid',
    async ({ facilityId, searchData, nights, parkId }, thunkAPI) => {
        try {
            let unitGridStore = store.getState().unitGrid;
            if (unitGridStore.facilityDetail) {
                if (unitGridStore.isOpenCampingGrid) {
                    thunkAPI.dispatch(getOpenCampingFacility({ facilityId, startDate: searchData.startDate }));
                } else if (unitGridStore.isTimeBaseGrid) {
                    thunkAPI.dispatch(getTimeBaseUnitGridData({ searchData, facilityId, parkId: parseInt(parkId), nights, duration: unitGridStore.facilityDetail.TimebaseDuration ? unitGridStore.facilityDetail.TimebaseDuration : 15 }));
                } else {
                    thunkAPI.dispatch(getUnitGridData({ searchData, facilityId, parkId: parseInt(parkId), nights }));
                }
            }
            return;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getUnitGridData = createAsyncThunk<any, { searchData: any, facilityId: any, parkId: any, nights: any }>(
    'unitGrid/getUnitGridData',
    async ({ searchData, facilityId, parkId, nights }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            //thunkAPI.dispatch(setUnitGridData(null));
            let requestData: any = {
                "FacilityId": facilityId,
                "UnitSort": searchData.unitSortBy,
                "StartDate": formatDate(searchData.startDate, store.getState().common.applicationSettings.apiDateFormat),
                "EndDate": formatDate(new Date(new Date(searchData.startDate).getTime() + (1000 * 60 * 60 * 24 * (nights - 1))), store.getState().common.applicationSettings.apiDateFormat),
                "InSeasonOnly": true,
                "WebOnly": true,
                "MaxDate": store.getState().common.futureBookingsDate.FutureBookingEndDate,
                "MinDate": store.getState().common.futureBookingsDate.FutureBookingStartDate,
                "IsADA": searchData.isADA,
                "RestrictADA": searchData.isADA,
                "UnitCategoryId": (searchData.unitCategoryId) ? parseInt(searchData.unitCategoryId) : 0,
                "SleepingUnitId": (searchData.sleepingUnitId) ? parseInt(searchData.sleepingUnitId) : 0,
                "MinVehicleLength": (searchData.minVehicleLength) ? parseInt(searchData.minVehicleLength) : 0,
                "UnitTypesGroupIds": searchData.unitTypesGroupIds,
                "AmenityIds": searchData.amenitiesIds,
                "CustomerId": 0,
                "customerClassificationId": store.getState().authentication.customerDetail?.customerClassificationId ? store.getState().authentication.customerDetail?.customerClassificationId : 0
            };
            if (parkId === 686) {
                requestData.AvailableUnitsOnly = true;
                requestData.Page = 1;
                requestData.PageSize = 20;
            }
            let gridResponse = await unitGridApiRequest.getUnitGridData(requestData);
            let unitGridData = Object.assign({}, gridResponse);
            let unitObj: any = {};
            let unitsVal: any = Object.values(unitGridData.Facility.Units);
            unitsVal.forEach((ele: any) => {
                unitObj[ele.UnitId] = ele;
            })
            unitGridData.Facility.Units = Object.assign({}, unitObj);
            unitGridData = processGridSlice(unitGridData);
            unitGridData = processUnitImage(unitGridData, searchData.nights);
            let headers: any = [];
            let units: any = Object.values(unitGridData.Facility.Units);
            if (units && units.length > 0) {
                let slices = Object.keys(units[0].Slices);
                if (slices && slices.length > 0) {
                    slices.forEach((ele) => {
                        headers.push({
                            day: formatDate(ele, 'E').charAt(0),
                            date: formatDate(ele, 'dd'),
                            month: formatDate(ele, 'LLLL')
                        });
                    });
                }
                unitGridData.Facility.sortedUnits = units.sort((a: any, b: any) => a.OrderByRaw - b.OrderByRaw);
            }
            unitGridData.headers = headers;
            unitGridData.gridHeader = "";
            if (headers.length > 0) {
                if (headers[0].month === headers[headers.length - 1].month) {
                    unitGridData.gridHeader = headers[0].month;
                } else {
                    unitGridData.gridHeader = headers[0].month + " - " + headers[headers.length - 1].month;
                }
            }
            thunkAPI.dispatch(hideContentLoader());
            thunkAPI.dispatch(setUnitGridData(unitGridData));
            onGridLoad(false);
            return;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            thunkAPI.dispatch(setUnitGridData(null));
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getTimeBaseUnitGridData = createAsyncThunk<any, { searchData: any, facilityId: any, parkId: any, nights: any, duration: any }>(
    'unitGrid/getTimeBaseUnitGridData',
    async ({ searchData, facilityId, parkId, nights, duration }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            //thunkAPI.dispatch(setUnitGridData(null));
            let requestData: any = {
                "FacilityId": facilityId,
                "UnitSort": searchData.unitSortBy,
                "StartDate": formatDate(searchData.startDate, store.getState().common.applicationSettings.apiDateFormat),
                "EndDate": formatDate(new Date(new Date(searchData.startDate).getTime() + (1000 * 60 * 60 * 24 * (nights - 1))), store.getState().common.applicationSettings.apiDateFormat),
                "InSeasonOnly": true,
                "WebOnly": true,
                "MaxDate": store.getState().common.futureBookingsDate.FutureBookingEndDate,
                "MinDate": store.getState().common.futureBookingsDate.FutureBookingStartDate,
                "IsADA": searchData.isADA,
                "RestrictADA": searchData.isADA,
                "UnitCategoryId": (searchData.unitCategoryId) ? parseInt(searchData.unitCategoryId) : 0,
                "SleepingUnitId": (searchData.sleepingUnitId) ? parseInt(searchData.sleepingUnitId) : 0,
                "MinVehicleLength": (searchData.minVehicleLength) ? parseInt(searchData.minVehicleLength) : 0,
                "UnitTypesGroupIds": searchData.unitTypesGroupIds,
                "AmenityIds": searchData.amenitiesIds,
                "CustomerId": 0,
                "TimeGrid": true,
                "TimeBetween": "5-20",
                "customerClassificationId": store.getState().authentication.customerDetail?.customerClassificationId ? store.getState().authentication.customerDetail?.customerClassificationId : 0
            };
            if (parkId === 686) {
                requestData.AvailableUnitsOnly = true;
                requestData.Page = 1;
                requestData.PageSize = 20;
            }
            let unitGridData = await unitGridApiRequest.getTimeBaseUnitGridData(requestData);
            unitGridData = processTimeBaseGridResult(unitGridData, duration);
            let headers: any = [];
            let units: any = Object.values(unitGridData.Facility.Units);
            if (units && units.length > 0) {
                let slices = Object.keys(units[0].Slices);
                if (slices && slices.length > 0) {
                    slices.forEach((ele) => {
                        headers.push({
                            day: formatDate(ele, 'E').charAt(0),
                            date: formatDate(ele, 'dd'),
                            month: formatDate(ele, 'LLLL')
                        });
                    });
                }
                unitGridData.Facility.sortedUnits = units.sort((a: any, b: any) => a.OrderByRaw - b.OrderByRaw);
            } else {
                unitGridData.Facility.sortedUnits = [];
            }
            unitGridData.headers = headers;
            unitGridData.gridHeader = "";
            if (headers.length > 0) {
                if (headers[0].month === headers[headers.length - 1].month) {
                    unitGridData.gridHeader = headers[0].month;
                } else {
                    unitGridData.gridHeader = headers[0].month + " - " + headers[headers.length - 1].month;
                }
            }
            thunkAPI.dispatch(hideContentLoader());
            thunkAPI.dispatch(setUnitGridData(unitGridData));
            onGridLoad(false);
            return;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            thunkAPI.dispatch(setUnitGridData(null));
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getUnitDetail = createAsyncThunk<any, { unitId: any, startDate: any, apiDateFormat: string, night: number }>(
    'unitGrid/getUnitDetail',
    async ({ unitId, startDate, apiDateFormat, night }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            let customerClassificationId = store.getState().authentication?.customerDetail?.customerClassificationId ? store.getState().authentication?.customerDetail?.customerClassificationId : 0;
            const unitDetail = await unitGridApiRequest.getUnitDetail(unitId, startDate, apiDateFormat, night, customerClassificationId);
            thunkAPI.dispatch(setUnitDetail(unitDetail));
            thunkAPI.dispatch(hideContentLoader());
            return;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            thunkAPI.dispatch(setUnitDetail(null));
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getUnitAvailability = async (unitId: any, startDate: any, nights: any, apiDateFormat: any, isReservationDraw: boolean, isRestriction: boolean, customerClassificationId: any, callback: any) => {
    if (isReservationDraw || isRestriction) {
        callback(null);
    } else {
        try {
            const unitAvailability = await unitGridApiRequest.getUnitAvailability(unitId, startDate, nights, apiDateFormat, customerClassificationId);
            callback(unitAvailability);
        } catch (error: any) {
            callback(null);
        }
    }
}

export const getOpenCampingFacility = createAsyncThunk<any, { facilityId: any, startDate: any }>(
    'unitGrid/getOpenCampingFacility',
    async ({ facilityId, startDate }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            //thunkAPI.dispatch(setUnitGridData(null));
            const unitGridData = await unitGridApiRequest.getOpenCampingFacility(facilityId, startDate, store.getState().common.applicationSettings.apiDateFormat);
            let dates = Object.keys(unitGridData.Facility.Dates);
            let headers: any = [];
            if (dates && dates.length > 0) {
                unitGridData.Facility.sortedDates = Object.values(unitGridData.Facility.Dates).sort((a: any, b: any) => a.Date - b.Date);
                unitGridData.Facility.sortedDates.forEach((ele: any) => {
                    headers.push({
                        day: formatDate(ele.Date, 'E').charAt(0),
                        date: formatDate(ele.Date, 'dd'),
                        month: formatDate(ele.Date, 'LLLL')
                    });
                });
            }
            unitGridData.headers = headers;
            unitGridData.gridHeader = "";
            if (headers.length > 0) {
                if (headers[0].month === headers[headers.length - 1].month) {
                    unitGridData.gridHeader = headers[0].month;
                } else {
                    unitGridData.gridHeader = headers[0].month + " - " + headers[headers.length - 1].month;
                }
            }
            thunkAPI.dispatch(hideContentLoader());
            thunkAPI.dispatch(setUnitGridData(unitGridData));
            onGridLoad(true);
            return;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            thunkAPI.dispatch(setUnitGridData(null));
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const markUnitAsFavourite = createAsyncThunk<any, { unitId: any }>(
    'unitGrid/markUnitAsFavourite',
    async ({ unitId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await unitGridApiRequest.markUnitAsFavourite(unitId);
            thunkAPI.dispatch(hideContentLoader());
            if (response) {
                let unitFavouriteList = Object.assign([], store.getState().unitGrid.unitFavouriteList);
                unitFavouriteList.push({
                    UnitId: unitId
                });
                thunkAPI.dispatch(setUnitFavouriteList(unitFavouriteList));
            }
            return;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const markUnitAsUnFavourite = createAsyncThunk<any, { unitId: any }>(
    'unitGrid/markUnitAsUnFavourite',
    async ({ unitId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await unitGridApiRequest.markUnitAsUnFavourite(unitId);
            thunkAPI.dispatch(hideContentLoader());
            if (response) {
                let unitFavouriteList = Object.assign([], store.getState().unitGrid.unitFavouriteList);
                let index = unitFavouriteList.findIndex((ele: any) => { return ele.UnitId === unitId });
                if (index >= 0) {
                    unitFavouriteList.splice(index, 1);
                }
                thunkAPI.dispatch(setUnitFavouriteList(unitFavouriteList));
            }
            return;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const checkRestractionforBooking = createAsyncThunk<boolean, { requestData: any }>(
    'unitGrid/checkRestractionforBooking',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await unitGridApiRequest.checkRestractionforBooking(requestData);
            thunkAPI.dispatch(hideContentLoader());
            let isSuccess = true;
            if (response && response.ErrorMessage !== "") {
                thunkAPI.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Information", message: response.ErrorMessage }));
                isSuccess = false;
            }
            return isSuccess;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return false;
        }
    }
)

export const checkRestractionAndLockforBooking = createAsyncThunk<boolean, { requestData: any }>(
    'unitGrid/checkRestractionAndLockforBooking',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await unitGridApiRequest.checkRestractionAndLockforBooking(requestData);
            if (response && response.ErrorMessage !== "") {
                thunkAPI.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Information", message: response.ErrorMessage }));
                thunkAPI.dispatch(hideContentLoader());
                return false;
            }
            thunkAPI.dispatch(hideContentLoader());
            let tempPreCart = localStorage.getItem("tempPreCartData") ? JSON.parse(localStorage.getItem("tempPreCartData") || "{}") : null;
            if (tempPreCart) {
                //thunkAPI.dispatch(setFacilityPreCartDetail(null));
                let tempPreCartData = {
                    ...tempPreCart, isSelectedNewUnit: true, newUnitId: requestData.unitId,
                    arrivalDate: requestData.arrivalDate, nights: requestData.nights, isDateNightChanged: true
                }
                if (response.StartTime && requestData.startTime) {
                    tempPreCartData.startTime = response.StartTime;
                    tempPreCartData.endTime = response.EndTime;
                }
                localStorage.setItem("preCartData",
                    JSON.stringify(tempPreCartData));
                localStorage.removeItem("tempPreCartData")
                navigationService.navigation("/Facilities/SelectReservationPreCart");
            } else {
                if (response.StartTime && requestData.startTime) {
                    requestData.startTime = response.StartTime;
                    requestData.endTime = response.EndTime;
                }
                localStorage.setItem("preCartData", JSON.stringify(requestData));
                navigationService.navigation("/Facilities/SelectReservationPreCart");
            }
            return true;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getUnNumberUnitId = async (startDate: string, nights: number, facilityId: number, callback: any) => {
    try {
        const unitId = await unitGridApiRequest.getUnNumberUnitId(startDate, nights, facilityId);
        callback(unitId);
    } catch (error: any) {
        callback(null);
    }
}

export const getUnitFavoriteList = createAsyncThunk<any>(
    'unitGrid/getUnitFavoriteList',
    async (_, thunkAPI) => {
        try {
            const unitFavouriteList = await unitGridApiRequest.getUnitFavoriteList();
            return unitFavouriteList && unitFavouriteList.$values && unitFavouriteList.$values.length > 0 ? unitFavouriteList.$values : [];
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)


export const unitGridSlice = createSlice({
    name: 'unitGrid',
    initialState,
    reducers: {
        setHourArray: (state, action) => {
            state.timeBaseHoursArray = action.payload;
        },
        setSelectedHour: (state, action) => {
            state.selectedHour = action.payload;
        },
        setSelectedTimeBaseGridData: (state, action) => {
            state.selectedTimeBaseGridData = action.payload;
        },
        setFacilityDetail: (state, action) => {
            state.facilityDetail = action.payload.facilityDetail;
            if (action.payload.isTimeBaseGrid !== undefined) {
                state.isTimeBaseGrid = action.payload.isTimeBaseGrid;
            }
            if (action.payload.isOpenCampingGrid !== undefined) {
                state.isOpenCampingGrid = action.payload.isOpenCampingGrid;
            }
        },
        setUnitGridData: (state, action) => {
            state.unitGridData = action.payload;
        },
        setUnitDetail: (state, action) => {
            state.unitDetail = action.payload;
        },
        setFurthestCampsitesDate: (state, action) => {
            state.furthestCampsitesDate = action.payload;
        },
        setUnitFavouriteList: (state, action) => {
            state.unitFavouriteList = action.payload;
        },
        setIsProcessPreCart: (state, action) => {
            state.isProcessPreCart = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(getUnitFavoriteList.rejected, (state) => {
            state.unitFavouriteList = [];
        });
        builder.addMatcher(isAnyOf(getUnitFavoriteList.fulfilled), (state, action) => {
            state.unitFavouriteList = action.payload;
        });
    })
})

export const { setHourArray, setSelectedHour, setSelectedTimeBaseGridData, setFacilityDetail,
    setUnitGridData, setUnitDetail, setFurthestCampsitesDate, setUnitFavouriteList, setIsProcessPreCart } = unitGridSlice.actions;