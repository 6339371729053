import React from "react";
import { useAppSelector } from "../../app/store/configureStore";
import { ReactComponent as SpinnerSvg } from "../../app/layout/icons/spinner.svg";

type PreLoaderProps = {
    isPreLoaderShow? : boolean
};

export const PreLoader: React.FC<PreLoaderProps> = ({isPreLoaderShow}) => {
    const loader = useAppSelector((state) => state.loader);
    return (
        <React.Fragment>
            {loader.isPreLoaderShow || isPreLoaderShow? (
                <div className="pre-loader" role="alert" aria-live="polite" data-reach-alert="true">
                    <div className="icon-full">
                        <SpinnerSvg />
                    </div>
                    <p className="loader-large-text">We are processing your request...</p>
                    <p className="loader-small-text">Please do not refresh your browser.</p>
                </div>
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </React.Fragment>
    );
};

type ContentLoaderProps = {};
export const ContentLoader: React.FC<ContentLoaderProps> = () => {
    const loader = useAppSelector((state) => state.loader);
    return (
        <React.Fragment>
            {loader.isContentLoaderShow ? (
                <div className="content-loader">
                    <div role="alert" aria-live="polite" data-reach-alert="true">
                        <div className="inner-content-loader">
                            <div className="small-icon-full">
                                <SpinnerSvg />
                                <span> Fetching Content...</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </React.Fragment>
    );
};