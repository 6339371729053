import { rdrRequests, authorizedRDRequests, rdRequests } from "../httpRequest";
import { formatDate } from "../../util/util";

const unitGridApiRequest = {
    getFacilityDetailById: (facilityId: number) => rdrRequests.get(`fd/facilities/${facilityId}`),
    getUnitGridData: (requestData: any) => rdrRequests.post(`search/grid`, requestData),
    getUnitDetail: (unitId: number, startDate: string, apiDateFormat: string, night: number, customerClassificationId: any) => rdrRequests.get(`search/details/${unitId}/startdate/${formatDate(startDate, apiDateFormat)}/nights/${night}/0/${customerClassificationId}`),
    getUnitAvailability: (unitId: number, startDate: string, nights: number, apiDateFormat: string, customerClassificationId: any) => rdrRequests.get(`fd/availability/getbyunit/${unitId}/startdate/${formatDate(startDate, apiDateFormat)}/nights/${nights}/true/${customerClassificationId}`),
    getOpenCampingFacility: (facilityId: number, startDate: string, apiDateFormat: string) => rdrRequests.get(`search/occupancy/${facilityId}/startdate/${formatDate(startDate, apiDateFormat)}`),
    markUnitAsFavourite: (unitId: number) => authorizedRDRequests.post(`webaccessfacility/save/unitfavorite`, unitId),
    markUnitAsUnFavourite: (unitId: number) => authorizedRDRequests.post(`webaccessfacility/save/unitunfavorite`, unitId),
    getDateRestriction: (facilityId: number, furthestDefaultCampsitesId: string) => rdrRequests.get(`fd/restrictions/startend/facility/${facilityId}/category/${furthestDefaultCampsitesId}/unittype/0`),
    getTimeBaseUnitGridData: (requestData: any) => rdrRequests.post(`search/grid`, requestData),
    checkRestractionAndLockforBooking: (requestData: any) => authorizedRDRequests.post(`webaccessfacility/check/restractionandlockforbooking`, requestData),
    getUnNumberUnitId: (startDate: string, nights: number, facilityId: number) => rdrRequests.get(`search/next/${facilityId}/startdate/${startDate}/nights/${nights}`),
    getUnitFavoriteList: () => authorizedRDRequests.post(`webaccessfacility/getunitfavoritelist`, {}),
    checkRestractionforBooking: (requestData: any) => rdRequests.post(`webaccessfacility/check/restractionsforbooking`, requestData)
}

export default unitGridApiRequest;