import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authenticationHttpRequest from "../../app/api/authentication/authenticationHttpRequest";
import { getFutureBookingStartsEndsDates, setAlertMessage } from "./settingsSlice";
import navigationService from "../../app/history/history";
import { store } from "../../app/store/configureStore";
import { setWaitListShow } from "../facilities/components/facilities/facilitiesSlice";
import { encryptCustomerId, decryptCustomerId, openLoginModal } from "../../app/util/util";
import { CustomerDetail } from "../../app/models/common/customerDetail";
import { emptyCart } from "../customers/shoppingCartSlice";
import { CustomerDetailResponse } from "../../app/models/customer";
import { hidePreLoader, showPreLoader } from "../../modules/loader/loaderSlice";
import { onCloseModal, onOpenModal } from "../../app/util/ada";
import { setSearchStep } from "../facilities/components/search/searchSlice";

interface AuthenticationState {
    isCashierLoggedIn: boolean,
    isLoggedIn: boolean,
    customerName: string | null,
    customerId: number,
    cashierId: number | null,
    isLoginButtonEnable: boolean,
    isLoginSaveEnable: boolean,
    cashierName: string | null,
    accessToken: string | null,
    customerDetail: CustomerDetail | null,
    preChekInCount: number,
    isSsoLoggedIn: boolean,
    ssoCustomerName: string | null,
    ssoAccessToken: string | null,
    isSSOTokenExpired: boolean,
    isLoginPopUpOpen: boolean,
    isSSOLoginPopUpOpen: boolean,
    isWaitListOpenBox: boolean
}

const initialState: AuthenticationState = {
    isCashierLoggedIn: localStorage.getItem('cashierId') ? true : false,
    isLoggedIn: localStorage.getItem('customerId') ? true : false,
    customerName: localStorage.getItem('customerName') ? localStorage.getItem('customerName') : "",
    customerId: localStorage.getItem('customerId') ? Number(decryptCustomerId(localStorage.getItem('customerId'))) : 0,
    cashierId: localStorage.getItem('cashierId') ? Number(localStorage.getItem('cashierId')) : 0,
    isLoginButtonEnable: true,
    isLoginSaveEnable: false,
    cashierName: localStorage.getItem('cashierName') ? localStorage.getItem('cashierName') : "",
    accessToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null,
    customerDetail: localStorage.getItem('customerDetail') ? JSON.parse(localStorage.getItem('customerDetail') || "{}") : {},
    preChekInCount: 0,
    isSsoLoggedIn: localStorage.getItem('ssoCustomerName') ? true : false,
    ssoCustomerName: localStorage.getItem('ssoCustomerName') ? localStorage.getItem('ssoCustomerName') : "",
    ssoAccessToken: localStorage.getItem('ssoAccessToken') ? localStorage.getItem('ssoAccessToken') : null,
    isSSOTokenExpired: false,
    isLoginPopUpOpen: false,
    isSSOLoginPopUpOpen: false,
    isWaitListOpenBox: false
}

export const customerLogin = createAsyncThunk<boolean, { requestData: any }>(
    'authentication/customerLogin',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(setCustomerLoginData({
                isLoggedIn: false,
                customerId: 0,
                firstName: "",
                isLoginButtonEnable: false,
                isLoginSaveEnable: false,
                accessToken: null,
                customerDetail: {},
                isSsoLoggedIn: false,
                ssoCustomerName: "",
                ssoAccessToken: null
            }));
            const customerLoginData: CustomerDetailResponse = (requestData.newPassword != '' && requestData.confirmPassword != '') ? await authenticationHttpRequest.updateTemporaryPassword(requestData) : await authenticationHttpRequest.customerLogin(requestData);
            thunkAPI.dispatch(mapLoginInformation({ customerLoginData: customerLoginData }));
            thunkAPI.dispatch(getFutureBookingStartsEndsDates());
            return true;
        } catch (error: any) {
            thunkAPI.dispatch(setCustomerLoginData({
                isLoggedIn: false,
                customerId: 0,
                firstName: "",
                isLoginButtonEnable: true,
                isLoginSaveEnable: false,
                accessToken: null,
                customerDetail: {},
                isSsoLoggedIn: false,
                ssoCustomerName: "",
                ssoAccessToken: null
            }));
            return false;
        }
    }
)

export const customerLogOut = createAsyncThunk<any, { isOpenLogin: boolean, isNotNeedtoPageRedirect?: boolean }>(
    'authentication/customerLogOut',
    async ({ isOpenLogin, isNotNeedtoPageRedirect }, thunkAPI) => {
        thunkAPI.dispatch(emptyCart()).then(() => {
            thunkAPI.dispatch(setWaitListShow(false));
            localStorage.removeItem("customerId");
            localStorage.removeItem("customerName");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("customerDetail");
            localStorage.removeItem("ssoCustomerName");
            localStorage.removeItem("ssoAccessToken");
            localStorage.removeItem("@secure.s.okta-cache-storage");
            localStorage.removeItem("@secure.s.okta-token-storage");
            localStorage.removeItem("okta-original-uri-storage");
            thunkAPI.dispatch(setCustomerLoginData({
                isLoggedIn: false,
                customerId: 0,
                firstName: "",
                isLoginButtonEnable: true,
                isLoginSaveEnable: false,
                accessToken: null,
                customerDetail: {},
                isSsoLoggedIn: false,
                ssoCustomerName: "",
                ssoAccessToken: null
            }));
            thunkAPI.dispatch(getFutureBookingStartsEndsDates());
            if (!isNotNeedtoPageRedirect) {
                thunkAPI.dispatch(setSearchStep("home"));
                navigationService.navigation("/");
            }
            //window.location.reload();
            if (isOpenLogin) {
                setTimeout(() => {
                    openLoginModal();
                }, 0);
            }
        });
    }
)

export const mapLoginInformation = createAsyncThunk<any, { customerLoginData: CustomerDetailResponse, isSSOLogin?: boolean }>(
    'authentication/mapLoginInformation',
    async ({ customerLoginData, isSSOLogin }, thunkAPI) => {
        let isPasswordExpired = customerLoginData.isPasswordExpired;
        let commonToken = localStorage.getItem('accessToken');
        if (customerLoginData.customerId > 0) {
            let customerDetail = {
                customerClassificationId: customerLoginData.customerClassificationId,
                allowSpecialBenefits: customerLoginData.allowSpecialBenefits,
                isOrganization: customerLoginData.isOrganization,
                firstName: customerLoginData.firstName,
                lastName: customerLoginData.lastName,
                email: customerLoginData.email,
                isPasswordExpired: isPasswordExpired,
                isTemporaryCustomer: customerLoginData.isTemporaryCustomer,
                precheckinCount: customerLoginData.precheckinCount,
                isGuestCustomer: customerLoginData.isGuestCustomer
            }
            if (customerLoginData && !customerLoginData.token && commonToken)
                customerLoginData.token = commonToken!;
            thunkAPI.dispatch(setCustomerLoginData({
                isLoggedIn: isPasswordExpired && !isSSOLogin ? false : true,
                customerId: isPasswordExpired && !isSSOLogin ? 0 : customerLoginData.customerId,
                firstName: isPasswordExpired && !isSSOLogin ? "" : customerLoginData.firstName,
                isLoginButtonEnable: true,
                isLoginSaveEnable: isPasswordExpired,
                accessToken: isPasswordExpired && !isSSOLogin ? null : customerLoginData.token,
                customerDetail: isPasswordExpired && !isSSOLogin ? {} : customerDetail,
                preChekInCount: isPasswordExpired && !isSSOLogin ? 0 : customerLoginData.precheckinCount
            }));
            if (!isPasswordExpired || isSSOLogin) {
                const facilities = store.getState().facilities;
                localStorage.setItem('customerId', encryptCustomerId(customerLoginData.customerId));
                localStorage.setItem('customerName', customerLoginData.firstName);
                localStorage.setItem('accessToken', customerLoginData.token);
                localStorage.setItem('customerDetail', JSON.stringify(customerDetail));
                onCloseModal("loginModal", false);
                store.dispatch(setIsLoginPopUpOpen(false));

                if (facilities.waitListShow) {
                    onOpenModal("waitlist_open_box", "waitListBoxHeader", "wlSave", "waitListBoxHeader");
                    store.dispatch(setIsWaitListOpenBox(true));
                }
            }
        } else {
            thunkAPI.dispatch(setCustomerLoginData({
                isLoggedIn: false,
                customerId: 0,
                firstName: "",
                isLoginButtonEnable: true,
                isLoginSaveEnable: false,
                accessToken: null,
                customerDetail: {}
            }));
            thunkAPI.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Login Failed", message: customerLoginData.message }));
        }
    }
)

export const customerSSOLogin = createAsyncThunk<any, { requestData: any }>(
    'WebAccessCustomer/SSO/ProcessSSOLogin',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            localStorage.setItem("ssoCustomerName", requestData.name)
            localStorage.setItem("ssoAccessToken", requestData.ssoAccessToken)
            thunkAPI.dispatch(setCustomerLoginData({
                isLoggedIn: false,
                customerId: 0,
                firstName: "",
                isLoginButtonEnable: true,
                isLoginSaveEnable: false,
                accessToken: null,
                customerDetail: {},
                isSsoLoggedIn: true,
                ssoCustomerName: requestData.name,
                ssoAccessToken: requestData.ssoAccessToken
            }));
            const customerLoginData = await authenticationHttpRequest.processSSOLogin(requestData);
            if (customerLoginData && customerLoginData.token) {
                thunkAPI.dispatch(mapLoginInformation({ customerLoginData: customerLoginData, isSSOLogin: true }));
                if(customerLoginData.VerifyAccountInformation)
                {         
                    navigationService.navigation("/customers/VerifyAccountInformation");
                }
                else
                {
                    navigationService.navigation((window as any).oktaOriginalUri);
                }
            } else if (!customerLoginData) {
                localStorage.removeItem("isProcessPreCart");
                localStorage.removeItem("reservationParam");
                localStorage.removeItem("isSelectedUnit");
                localStorage.removeItem("isProcessSelectedUnit");
                navigationService.navigation("/Customers/NewCustomer");
            }
            thunkAPI.dispatch(hidePreLoader());
            thunkAPI.dispatch(getFutureBookingStartsEndsDates());
            return customerLoginData;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            thunkAPI.dispatch(setSearchStep("home"));
            navigationService.navigation("/");
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const sendSSODuplicateConfirmationMail = createAsyncThunk<any, { requestData: any }>(
    'WebAccessCustomer/SSO/SendSSODuplicateConfirmationMail',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const sendSSODuplicateConfirmationMailData = await authenticationHttpRequest.sendSSODuplicateConfirmationMail(requestData);
            thunkAPI.dispatch(hidePreLoader());
            return sendSSODuplicateConfirmationMailData;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const verifyUserAccountSSOLogin = createAsyncThunk<any, { requestData: any }>(
    'WebAccessCustomer/SSO/VerifyUserAccountSSOLogin',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const verifyUserAccountSSOLoginData = await authenticationHttpRequest.verifyUserAccountSSOLogin(requestData);
            thunkAPI.dispatch(hidePreLoader());
            return verifyUserAccountSSOLoginData;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setCustomerLoginData: (state, action) => {
            state.isLoggedIn = action.payload.isLoggedIn;
            state.customerId = action.payload.customerId;
            state.customerName = action.payload.firstName;
            state.isLoginButtonEnable = action.payload.isLoginButtonEnable;
            state.isLoginSaveEnable = action.payload.isLoginSaveEnable;
            state.accessToken = action.payload.accessToken;
            state.customerDetail = action.payload.customerDetail;
            state.preChekInCount = action.payload.preChekInCount;
            // if (state.ssoCustomerName !== undefined) {
            if (action.payload.ssoCustomerName !== undefined) {
                state.isSsoLoggedIn = action.payload.isSsoLoggedIn;
                state.ssoCustomerName = action.payload.ssoCustomerName;
                state.ssoAccessToken = action.payload.ssoAccessToken;
            }
        },
        setLoginSave: (state) => {
            state.isLoginSaveEnable = false;
        },
        setIsSSOTokenExpired: (state, action) => {
            state.isSSOTokenExpired = action.payload;
        },
        setIsLoginPopUpOpen: (state, action) => {
            state.isLoginPopUpOpen = action.payload;
        },
        setIsSSOLoginPopUpOpen: (state, action) => {
            state.isSSOLoginPopUpOpen = action.payload;
        },
        setIsWaitListOpenBox: (state, action) => {
            state.isWaitListOpenBox = action.payload;
        }
    },
    extraReducers: {}
})

export const { setCustomerLoginData, setLoginSave, setIsSSOTokenExpired , setIsLoginPopUpOpen, setIsSSOLoginPopUpOpen, setIsWaitListOpenBox} = authenticationSlice.actions;