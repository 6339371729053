import { createAsyncThunk, createSlice, isAllOf, isAnyOf } from "@reduxjs/toolkit";
import membershipApiRequests from "../../app/api/membership/membershipApiRequests";
import passesApiRequest from "../../app/api/passes/passesApiRequest";
import navigationService from "../../app/history/history";
import { setShoppingCartTimer } from "../common/timerSlice";
import { hideContentLoader, hidePreLoader, showContentLoader, showPreLoader } from "../loader/loaderSlice";


interface MembershipDetailState {
    membershipDetails: any,
    selectedMembershipDetails: any,
    membershipCartDetails: any,
    membershipMultiUserRestrictions: any,
    customerAddressDetails: any,
}

const initialState: MembershipDetailState = {
    membershipDetails: null,
    selectedMembershipDetails : null,
    membershipCartDetails: null,
    membershipMultiUserRestrictions: null,
    customerAddressDetails: null,
}

export const loadMembershipData = createAsyncThunk<any>(
    'membership/loadMembershipData',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const details = await membershipApiRequests.loadMembershipData();
            thunkAPI.dispatch(hidePreLoader());
            return details;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const loadSelectedMembershipDetails = createAsyncThunk<any, { membershipParameters: any }>(
    'membership/getSelectedMembershipDetails',
    async ({ membershipParameters }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const membershipDetails = await membershipApiRequests.getSelectedMembershipDetails(membershipParameters);
            thunkAPI.dispatch(hidePreLoader());
            return membershipDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const validateMembershipCartDetails = createAsyncThunk<any, { membershipParameters: any }>(
    'membership/validateMembershipCartDetails',
    async ({ membershipParameters }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const membershipResponse = await membershipApiRequests.validateMembershipCartDetails(membershipParameters);
            if (membershipResponse.IsSuccess) {
                localStorage.setItem('cart', JSON.stringify(membershipResponse));
                localStorage.setItem('shoppingCartKey', membershipResponse.ShoppingCartKey);
                navigationService.navigation("/Customers/ShoppingCart");
                thunkAPI.dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: false, shoppingCartTimerStartTime: null, shoppingCartTimerEndTime: null }));
                setTimeout(()=>{
                    thunkAPI.dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: true, shoppingCartTimerStartTime: membershipResponse.StartTime, shoppingCartTimerEndTime: membershipResponse.EndTime }));
                },0);               
            } else {
                thunkAPI.dispatch(hidePreLoader());              
            }
            return membershipResponse;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const validateMembershipMultiUserRestrictions = createAsyncThunk<any, { multiMembershipParameters: any }>(
    'membership/validateMembershipMultiUserRestrictions',
    async ({ multiMembershipParameters }, thunkAPI) => {
        try {
            const membershipDetails = await membershipApiRequests.validateMembershipMultiUserRestrictions(multiMembershipParameters);
            return membershipDetails;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const getLoggedInCustomerAddress = createAsyncThunk<any>(
    'Pass/getLoggedInCutomerAddress',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const customerAddress = await passesApiRequest.getCustomerAddressDetail();
            thunkAPI.dispatch(hideContentLoader());
            return customerAddress;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const membershipSlice = createSlice({
    name: 'membership',
    initialState,
    reducers: {
        setMembershipDetails: (state, action) => {
            state.membershipDetails = action.payload
        },
        setSelectedMembershipDetail: (state, action) => {
            state.selectedMembershipDetails = action.payload
        },
        setMembershipCartDetail: (state, action) => {
            state.membershipCartDetails = action.payload
        },
        setMembershipMultiUserRestrictions: (state, action) => {
            state.membershipMultiUserRestrictions = action.payload
        }
    },
    extraReducers: (builder => {
        builder.addCase(loadMembershipData.rejected, (state) => {
            state.membershipDetails = [];
        });
        builder.addCase(loadSelectedMembershipDetails.rejected, (state) => {
            state.selectedMembershipDetails = [];
        });
        builder.addCase(validateMembershipCartDetails.rejected, (state) => {
            state.membershipCartDetails = [];
        });
        builder.addCase(validateMembershipMultiUserRestrictions.rejected, (state) => {
            state.membershipMultiUserRestrictions = [];
        });        
        builder.addCase(getLoggedInCustomerAddress.rejected, (state) => {
            state.customerAddressDetails = null;
        });
        builder.addMatcher(isAnyOf(loadMembershipData.fulfilled), (state, action) => {
            state.membershipDetails = action.payload;
        });
        builder.addMatcher(isAnyOf(loadSelectedMembershipDetails.fulfilled), (state, action) => {
            state.selectedMembershipDetails = action.payload;
        });
        builder.addMatcher(isAnyOf(validateMembershipCartDetails.fulfilled), (state, action) => {
            state.membershipCartDetails = action.payload;
        });
        builder.addMatcher(isAnyOf(validateMembershipMultiUserRestrictions.fulfilled), (state, action) => {
            state.membershipMultiUserRestrictions = action.payload;
        });
        builder.addMatcher(isAnyOf(getLoggedInCustomerAddress.fulfilled), (state, action) => {
            state.customerAddressDetails = action.payload;
        });
    })
})
export const { setMembershipDetails, setSelectedMembershipDetail, setMembershipCartDetail, setMembershipMultiUserRestrictions } = membershipSlice.actions;